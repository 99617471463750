import React from 'react';
import NotFoundText from './NotFoundText';


const NotFound = () => {
    return (
        <>
            <NotFoundText />
        </>
    );
};

export default NotFound;